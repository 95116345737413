:root { 
	--button: #3A86B7;
	--button-hover: #07639F;
	--background: #F4F4F4;
	--background2: #89B7D5;
	--textB: black;
	--textW: #C0C0C0;
	--bannerBackground: #231F20;
	--CarouselBG1: #1B78B4;
	--CarouselBG2: #3A86B7;
	--red: #F25F5C;
}