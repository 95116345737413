* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Add the viewport meta tag */
/*head {
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
}*/

html{
	font-size: 16px;
}

 body {
	 background-color: var(--background);
	 /*background-image: url("FibreOptics.jpg");
	 background-size: 100vw auto;
	 background-repeat: no-repeat;
	 background-attachment: fixed;*/
 }
 
/* Navigation bar */
.navbar {
  display: flex;
  align-items: stretch;				/*make the buttons have the same height as the navbar*/
  justify-content: space-between; 	/*distribute the buttons evenly*/
  background-color: var(--button);
  color: var(--white);
  height: 5vw;
}

.navbar a {
	color: var(--textW);
	text-decoration: none;
	padding: 0rem;
	display: flex; 
	align-items: center; 			/* center the text vertically */ 
	justify-content: center; 		/* center the text horizontally */
}

.navbar a:hover {
  background-color: var(--button-hover);
}

/* Image or banner */
.banner {
  width: 100%;
  background-image: url(Images/AdtechLogo.jpg); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  /*background-color: var(--bannerBackground);*/
}

/* About us section */
.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vw;
}

.about h2 {
  text-align: center;
}

.about p {
  width: 80%;
  text-align: center;
}

.about li {
  list-style-position: outside;
  text-align: left;
}

/* our story story */
.centreImage {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.centreImage img {
  object-fit: cover;
  width: 100%;
}

.grid {
	display: grid;
	grid-template-rows: repeat(4, auto);
	align-content: center;
	grid-gap: 5vw 5vw;
}

.grid-item {
	/*display: block;*/
	position: relative;

  max-width: 100%;
  height: auto;
  object-fit: cover; 
}

.grid-item img {
  width: 100%;
  height: auto;
  object-fit: cover; 
}

.grid-item:hover img {
  opacity: 0.5;
}

/* Style the image container */
.grid-container {
	position: relative;
	width: 100%;
	height: auto;
	margin: 0 auto;
}

/* Style the image */
.grid-container img {
	 max-width: 100%;
	 aspect-ratio: 1;
	 object-fit: cover; 
   filter: brightness(90%);
}

.grid-container:hover img {
  opacity: 0.5;
  filter: brightness(100%);
}

/* Style the title */
.grid-container p.titlez {
	position: absolute;
	top: 0.5vw;
	left: 0;
	right: 0;
	color: var(--bs-gray-100);
	font-weight: bold;
	text-align: center;
  /*-webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: var(--bs-gray-800);*/
}

.grid-container:hover p.titlez {
	color: var(--bs-gray-800);
  /*-webkit-text-stroke-color: var(--bs-gray-100);*/
}


.contactGrid {
	display: grid;
	grid-template-rows: repeat(4, auto);
	align-content: center;
	grid-gap: 5vw 5vw;
}

.contactGrid-item {
	/*display: block;*/
	position: relative;
}

.contactGrid-item img {
  width: 100%;
  height: auto;
  object-fit: cover; 
}

.contactGrid-item h2 {
	text-align: center;
}

.contactGrid-item p {
	text-align: left;
}




/* Style the image container */
.image-container {
	position: relative;
	height: auto;
	margin: 0 auto;
	display: flex; /* Use display flex */
	justify-content: center; /* Align items to center */
	overflow: hidden; /* Clip any overflowing content */
}

/* Style the image */
.image-container img {
	max-width: 100%;
	height: auto;
	object-fit: cover; 
	align-items: center;
}

/* Style the title */
.image-container p.titlez {
	position: absolute;
	left: 0;
	right: 0;
	color: var(--textB);
	font-weight: bold;
	text-align: center;
}

/* Style the paragraph */
.image-container p.paragraphz {
	position: absolute;
	left: 0;
	right: 0;
	color: var(--textB);
	text-align: center;
}


form {
    width: 400px;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 1.5;
}

label {
    display: block;
    margin-bottom: 5px;
}

/* input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--textB);
    border-radius: 5px;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 15px;
} */

input[type="submit"] {
    background-color: var(--button-hover);
    color: var(--bs-dark);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

input[type="submit"]:hover {
    background-color: var(--button);
}

.footer-basic {
  padding:40px 0;
  background-color:var(--button);
  color:var(--white);
}

.footer-basic ul {
  padding:0;
  list-style:none;
  text-align:center;
  font-size:1rem;
  line-height:1.6;
  margin-bottom:0;
}

.footer-basic li {
  padding:0 10vw;
}

.footer-basic ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.8;
}

.footer-basic ul a:hover {
  opacity:1;
}

.footer-basic .social {
  text-align:center;
  padding-bottom:1%;
}

.footer-basic .social > a {
  display:inline-block;
  margin:0 0.5rem;
  opacity:0.75;
}

.socialicons {
  width:2.5rem;
  height:2.5rem;
}

.footer-basic .social > a:hover {
  opacity:0.9;
}

.footer-basic .copyright {
  margin-top:1rem;
  text-align:center;
  font-size:1rem;
  color:var(--white);
  margin-bottom:0;
}

.footer .sliderImg {
  height:390px;
  width:770px;
  object-fit: cover;
}

.centreImage img {
  aspect-ratio: 1.7;
}

/* Add media queries for different screen sizes */
@media (min-width: 320px) {
  /* For mobile devices, stack the navbar items vertically */
  .navbar {
    flex-direction: column;
	  height: 30vw;
  }
  .navbar a {
    width: 100vw;
    font-size: 1.8rem;
    height: 10vw;
  }
  .banner {
    height: 25vw;
  }
  .about h2 {
    font-size: 4rem;
  }
  .about p {
    font-size: 2rem;
  }
  .about li {
    font-size: 2rem;
  }

  .aboutService h2 {
    font-size: 4rem;
  }

  .aboutService p {
    font-size: 2rem;
  }
  .aboutService li {
    font-size: 2rem;
  }
  .grid {
	margin: 0% 10%;
    grid-template-columns: repeat(1, 1fr);
  }
  .image-container {
    width: 80vw;
  }
	.image-container p.titlez {
	top: 0.5vw;
	font-size: 3.8rem;
	}

.image-container p.paragraphz {
	bottom: 1vw;
	font-size: 1.8rem;
	}
	
  .contactGrid {
	margin: 0% 10%;
    grid-template-columns: repeat(1, 1fr);
    
  }
  .contactGrid-item h2 {
	font-size: 4rem;
}

.contactGrid-item p {
	font-size: 2rem;
}
.contactGrid-item label {
	font-size: 2rem;
	font-weight: bold;
}

.grid-container p.titlez {
	font-size: 3.8rem;
}

.footer{
  margin: 15px 10vw;
}

}

@media (min-width: 1281px) {
  /* For desktop devices, keep the navbar items horizontally */
  .navbar {
    flex-direction: row;
	  height: 5vw;
  }
  
  .navbar a {
	width: 33.3vw;
	font-size: 0.9rem;
	height: 5vw;
  }

  .banner {
    height: 20vw;
  }
  .about h2 {
    font-size: 2rem;
  }

  .about p {
    font-size: 1rem;
  }
  .about li {
    font-size: 1rem;
    padding-left: 25vw;
  }

  .aboutService h2 {
    font-size: 2rem;
  }

  .aboutService p {
    font-size: 1rem;
  }
  .aboutService li {
    font-size: 1rem;
  }

  
  .grid {
	margin: 0% 20%;
    grid-template-columns: repeat(2, 1fr);
  }
  .image-container {
    width: 30vw;
  }
  
  .image-container p.titlez {
	top: 0.5vw;
	font-size: 1.9rem;
	}

	.image-container p.paragraphz {
	bottom: 1vw;
	font-size: 0.9rem;
	}
	
	.contactGrid {
	margin: 0% 20%;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .contactGrid-item h2 {
	font-size: 2em;
}

.contactGrid-item p {
	font-size: 1rem;
}

.contactGrid-item label {
	font-size: 1rem;
	font-weight: normal;
}

.grid-container p.titlez {
	font-size: 1.9rem;
}

.centreImage img {
  aspect-ratio: 1;
}

.footer{
  margin: 30px 30vw;
}

}